import { useEffect, useState } from "react";
import { useAppStore } from "~/store";
import { getCookie } from "~/utils/cookie";
import { saveLeoCacheSeen, type Thread } from "~/utils/leocache";

export function useSaveLeoCacheSeen(
  feedType: string | undefined,
  threads: Thread[]
) {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [lastSize, setLastSize] = useState<number>(0);

  useEffect(() => {
    if (feedType !== "foryou") return;

    if (
      !activeAccount ||
      (!activeAccount?.active?.key_auths?.[0]?.[0] &&
        !activeAccount?.posting?.key_auths?.[0]?.[0])
    )
      return;
    if (threads.length === lastSize) return;

    const permlinksFromThreads = threads.map((thread: Thread) => {
      return thread?.permlink;
    });
    // insert here
    void (async function () {
      const { auth, proxy } = getCookie("__session");
      const _signature = window.localStorage.getItem("activeAccount") ?? "";

      if (!_signature) return;

      let timestamp;

      try {
        timestamp = JSON.parse(atob(auth))?.timestamp;
      } catch {
        //console.log("User logged in with ", proxy);
      }

      const saveSeenData = {
        permlinks: permlinksFromThreads,
        authorization: {
          public_key:
            proxy === "hivesigner"
              ? activeAccount?.active?.key_auths[0][0] ||
                activeAccount?.posting?.key_auths[0][0]
              : activeAccount?.posting?.key_auths[0][0],
          account: activeAccount?.name,
          signature: _signature,
          hivesigner: proxy === "hivesigner",
          timestamp: timestamp || 0
        }
      };

      saveLeoCacheSeen(saveSeenData as unknown as any);
    })();
    setLastSize(threads.length);
  }, [threads, feedType]);
}
