import { cache } from "~/utils/cache";

export const hashCode = function (str: String) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return `${hash}`.replaceAll("-", "");
};

export async function action({ request }) {
  const body = JSON.parse(await request.text());

  const lookup = cache.generic.get(body.hash_id);

  if (lookup !== undefined) {
    return lookup;
  }

  if (body.request_type === "post") {
    const generated_hash = hashCode(
      body.bulk_data.map((a: any) => a.author + a.permlink).join("|")
    );

    const newThreads = await cache.getThreads(body.bulk_data as any);

    cache.generic.set(generated_hash, newThreads);

    return new Response(JSON.stringify([...newThreads]), {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "public, max-age=31536000, immutable"
      }
    });
  }
}
