import React from "react";
import {
  leocache,
  type ThreadsFetcher,
  type Thread,
  generateFeedSession
} from "~/utils/leocache";

function removeDuplicates(threads: any[]) {
  const seen = new Set();
  return threads.filter(thread => {
    const permlink = thread?.permlink;
    if (seen.has(permlink)) {
      return false;
    }
    seen.add(permlink);
    return true;
  });
}

function uniqueThreads(baseThreads: Thread[], newThreads: Thread[]): Thread[] {
  const basePermlinks = new Set(baseThreads.map(thread => thread.permlink));
  return newThreads.filter(thread => !basePermlinks.has(thread.permlink));
}

interface ThreadsV2Args {
  initialThreads?: Thread[];
  initialNextPage?: string;
  initialSession?: string;
  author?: string;
  sort?: string;
  depth?: string;
  search?: string;
  feed?: string;
}

export function useThreadsV2(args: ThreadsV2Args) {
  const [threads, setThreads] = React.useState<Thread[]>(
    args.initialThreads || []
  );
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const [nextPage, setNextPage] = React.useState<string>(
    args.initialNextPage || "0"
  );
  const [session, setSession] = React.useState<string>(
    args.initialSession || "00000000"
  );

  const [newThreadsWaitToPush, setNewThreadsWaitToPush] = React.useState<
    Thread[]
  >([]);

  // Store initial args to compare later
  const initialArgs = React.useRef({
    author: args.author,
    sort: args.sort,
    depth: args.depth,
    search: args.search
  });

  React.useEffect(() => {
    if ((args?.initialThreads || []).length > 0) {
      setThreads(args.initialThreads || []);
    }
  }, [args.initialThreads]);

  React.useEffect(() => {
    const argsChanged =
      args.author !== initialArgs.current.author ||
      args.sort !== initialArgs.current.sort ||
      args.depth !== initialArgs.current.depth ||
      args.search !== initialArgs.current.search;

    // Only fetch if there are no initial threads or if args have changed
    if (!args.initialThreads || argsChanged) {
      fetchMore({
        page: "0",
        author: args.author,
        search: args.search,
        sort: args.sort,
        depth: args.depth,
        renew: true
      });

      initialArgs.current = {
        author: args.author,
        sort: args.sort,
        depth: args.depth,
        search: args.search
      };
    }
  }, [args.author, args.sort, args.depth, args.search]);

  // check for new threads
  React.useEffect(() => {
    if (args.feed !== "latest") return;

    const interval = setInterval(async () => {
      if (newThreadsWaitToPush.length > 0) {
        setNewThreadsWaitToPush([]);
      }

      try {
        const response = await leocache.getV2ThreadFeed({
          feed: "latest",
          page: "0",
          session: generateFeedSession()
        });

        // const sanitizeDuplicates = (newThread: Thread) => {
        //   return newThreadsWaitToPush.find(
        //     thread => thread?.permlink !== newThread?.permlink
        //   );
        // };

        if (!response.threads || response.threads.length === 0) return;

        // const threadsToPush = response.threads.filter(sanitizeDuplicates);

        const threadsToPush = uniqueThreads(threads, response.threads);

        setNewThreadsWaitToPush(threadsToPush);
      } catch {
        return;
      }
    }, 20_000);

    return () => clearInterval(interval);
  }, [args.feed, threads]);

  const pushNewThreads = React.useCallback(() => {
    if (newThreadsWaitToPush.length === 0) return;

    setThreads(threads => [...newThreadsWaitToPush, ...threads]);
    setNewThreadsWaitToPush([]);
  }, [newThreadsWaitToPush]);

  const fetchMore = React.useCallback(
    async ({
      feed,
      page,
      session,
      author,
      query,
      sort,
      search,
      depth,
      renew
    }: ThreadsFetcher) => {
      if (Number(page) === -1) return;
      if (fetching) return;

      try {
        setFetching(true);

        let response;
        if (author) {
          if (depth !== undefined && search) {
            const threads = await leocache.authorSearch(author, search);
            response = { threads };
          } else if (depth !== undefined) {
            response = await leocache.getV2AuthorPageThreadFeed({
              page,
              author,
              sort,
              depth
            });
          } else {
            response = await leocache.getV2AuthorThreadFeed({
              feed,
              page,
              session,
              author
            });
          }
        } else if (search && query) {
          response = { threads: await leocache.tagSearch(query, search) };
        } else if (query) {
          response = await leocache.getV2TagThreadFeed({
            query,
            sort,
            page,
            session
          });
        } else {
          response = await leocache.getV2ThreadFeed({ feed, page, session });
        }

        if (typeof response.threads === "undefined") {
          if (!search && (!response.session || !response.nextPage)) {
            setError(true);
            setFetching(false);
            return;
          }
        }

        setNextPage(response.nextPage);
        setSession(response.session);

        const safeThreadResponse = response.threads || [];

        setThreads(threads =>
          renew || search
            ? response.threads
            : removeDuplicates([...threads, ...safeThreadResponse])
        );

        setFetching(false);

        return { threads: safeThreadResponse, nextPage, session };
      } catch {
        setError(true);
        setFetching(false);
      }
    },
    [fetching, nextPage]
  );

  function createFakeThread(thread: Thread) {
    setThreads(threads => [thread, ...threads]);
  }

  function dumpThreads() {
    setThreads([]);
  }

  return {
    threads,
    nextPage,
    session,
    fetching,
    error,
    newThreadsWaitToPush,
    fetchMore,
    createFakeThread,
    pushNewThreads,
    dumpThreads
  };
}
