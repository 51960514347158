import { hashCode } from "~/routes/helpers/shared.cache";

export type SharedCacheRequest = "account" | "post";

export default async function callSharedCache(
  request_type: SharedCacheRequest,
  bulk_data: string[]
) {
  const uniqueId = hashCode(
    bulk_data.map((a: any) => a.author + a.permlink).join("|")
  );

  return (
    await fetch(`/helpers/shared/cache`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      cache: "force-cache",
      keepalive: true,
      body: JSON.stringify({ request_type, bulk_data, hash_id: uniqueId })
    })
  ).json();
}
