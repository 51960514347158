import { useState, useEffect, useCallback, useMemo } from "react";
import { cache } from "~/utils/cache";
import type { CachedDiscussion } from "~/utils/leocache";
import callSharedCache from "~/utils/shared_cache/request";
import { isSSR } from "~/utils/ssr";
import type { PossibleThreadContent } from "~/utils/thread";

export const THREADS_AT_TIME = 6;

export enum ThreadFeedTypes {
  Latest,
  Trending,
  Post
}

function removeDuplicates(threads: any[]) {
  const seen = new Set();
  return threads.filter(thread => {
    const permlink = thread?.permlink || thread?.permlink;
    if (seen.has(permlink)) {
      return false;
    }
    seen.add(permlink);
    return true;
  });
}

export function useThreads(
  initialThreadIndices: PossibleThreadContent[],
  _threadIndices: CachedDiscussion[],
  feedType?: ThreadFeedTypes
) {
  let threadIndicesFromSession = _threadIndices;
  let threadLoadedFromSession = initialThreadIndices;

  const [threadIndices, setThreadIndices] = useState(threadIndicesFromSession);

  const [loadedThreads, setLoadedThreads] = useState<PossibleThreadContent[]>(
    () => threadLoadedFromSession
  );

  const [isLoadingThreads, setIsLoadingThreads] = useState(false);
  const [updatedIndiceChange, setUpdatedIndiceChange] = useState(false);
  const [toBeUpdated, setToBeUpdated] = useState([]);
  const [fakeThreadsQeue, setFakeThreadsQueue] = useState([]);

  const updateThreads = useCallback(
    (newThreads: any) => {
      setLoadedThreads(prevThreads =>
        removeDuplicates([...prevThreads, ...newThreads])
      );
    },
    [setLoadedThreads]
  );

  useEffect(() => {
    if (fakeThreadsQeue?.length >= 3) {
      setFakeThreadsQueue([]);
    }
  }, [fakeThreadsQeue]);

  const handleScroll = () => {
    if (
      window.scrollY >
      document.body.scrollHeight - window.innerHeight - 500
    ) {
      //loadMore();
      pushNewThreads();
      setToBeUpdated([]);
    }
  };

  const loadedThreadsLength = useMemo(
    () => loadedThreads?.length,
    [loadedThreads]
  );

  const loadMore = useCallback(async () => {
    setIsLoadingThreads(true);
    try {
      const toBeLoadedCount = loadedThreadsLength + THREADS_AT_TIME;
      const slicedIndices = threadIndices.slice(
        loadedThreadsLength,
        toBeLoadedCount
      );
      const newThreads = await callSharedCache("post", slicedIndices);
      await cache.getAccountsFromThreads(slicedIndices);
      updateThreads(newThreads);
    } catch (error) {
      console.error("loadMore error", error);
    } finally {
      setIsLoadingThreads(false);
    }
  }, [loadedThreadsLength, threadIndices, updateThreads]);

  const pushToStart = useCallback(newThread => {
    setLoadedThreads(prevThreads =>
      removeDuplicates([newThread, ...prevThreads])
    );
  }, []);

  function changeIndices(newIndices: CachedDiscussion[]): void {
    setLoadedThreads([]);
    setThreadIndices(newIndices);
    setUpdatedIndiceChange(false);
  }

  const linkedFeed = async () => {
    let feedResult;

    if (feedType === threadFeedTypes.Latest) {
      feedResult = await leocache.latestFeed;
    } else if (feedType === threadFeedTypes.Trending) {
      feedResult = await leocache.trendingFeed;
    }

    const sanitizedLastLoaded = loadedThreads.find(
      thread => thread?.fake !== true
    );

    if (!feedResult) {
      return { fullFeed: [], rawFeed: [] };
    }

    const lastLoadedTime = new Date(sanitizedLastLoaded?.created).getTime();
    const filteredEvents = feedResult
      .filter(event => new Date(event.created).getTime() > lastLoadedTime)
      .sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      );

    const fullFeed = await cache.getThreads(filteredEvents);

    return {
      fullFeed: fullFeed as PossibleThreadContent[],
      rawFeed: feedResult
    };
  };
  const pushNewThreads = feed => {
    const fakeThreadsApplied =
      loadedThreads.filter(thread => thread?.fake === true) || [];
    const sanitizeDuplicates = newThread =>
      loadedThreads.find(
        thread =>
          (thread?.permlink || thread?.permlink) ===
          (newThread?.permlink || newThread?.permlink)
      );

    const newLoadedThreads = (feed || toBeUpdated).filter(
      a => !sanitizeDuplicates(a)
    );
    if (newLoadedThreads.length === 0) return;

    newLoadedThreads.forEach(pushToStart);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toBeUpdated]);

  useEffect(() => {
    if (isSSR()) return;
    const interval = setInterval(async () => {
      const { fullFeed: feed, rawFeed } = await linkedFeed();
      if (window.scrollY < 500) {
        pushNewThreads(feed);
        setToBeUpdated([]);
      } else {
        pushNewThreads([]);
        setToBeUpdated(feed);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [loadedThreads, linkedFeed, pushNewThreads]);

  useEffect(() => {
    if (updatedIndiceChange === false) {
      if (loadedThreads?.length === 0) loadMore();
      setUpdatedIndiceChange(true);
    }
  }, [loadedThreads]);

  useEffect(() => {
    loadMore();
  }, [setLoadedThreads]);

  return [
    loadedThreads,
    loadMore,
    pushToStart,
    changeIndices,
    isLoadingThreads,
    toBeUpdated
  ];
}
